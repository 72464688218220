import "../legal.css";

import * as React from "react";
import { useTranslation } from "react-i18next";
// import { Trans } from "react-i18next"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import { Parallax } from "react-scroll-parallax";

import Layout from "../../../components/layout/Layout";
import Seo from "../../../components/seo";
import SectionLanding from "../../../components/section/sectionLanding";
import Mask3 from "../../../svg/masks/mask3.svg";
import { Link } from "gatsby-plugin-react-i18next";

export default function IndexPage() {
	const { t } = useTranslation();
	return (
		<Layout>
			<Seo title={t("Política de Cookies")} />
			<StaticImage
				src={`../../../images/contacto.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-10} className="parallaxImg">
				<StaticImage
					src={`../../../images/contacto.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<Mask3 />

			<SectionLanding title={t("Política de Cookies")} />

			<div className="legalBody invertColors" id="politicaCookies">
				<h2>1. Objeto</h2>
				<p>
					Esta Política de Cookies pretende dar cumplimiento a la Ley 34/2002,
					de 11 de julio, de Servicios de la Sociedad de la Información y
					Comercio Electrónico, (en adelante, LSSICE), al Reglamento General de
					protección de datos personales 2016/679 (en adelante, RGPD) y a la Ley
					Orgánica 3/2018 de protección de datos personales y garantía de los
					derechos digitales (en adelante, LOPDGDD).
				</p>
				<h2>
					2. Identificación del Responsable / Prestador de servicios de la
					sociedad de la información
				</h2>
				<p>
					En cumplimiento de lo establecido en el artículo 10 de la Ley 34/2002,
					de 11 de julio, de Servicios de la Sociedad de la Información y de
					Comercio Electrónico (en adelante, LSSICE), se informa de modo
					expreso, preciso e inequívoco, tanto a los usuarios, destinatarios del
					servicio, como a los órganos competentes, de los siguientes aspectos
					relativos al prestador de servicios de la sociedad de la información:
				</p>
				<p>
					Entidad / Editor: BEGIN RESTAURANTES, S.L.
					<br />
					CIF/NIF: B40626392
					<br />
					Dirección postal: Av. Cortes Valencianas, 50, CP 46015 Valencia
					(Valencia)
					<br />
					Correo electrónico: rrhh@beginrestaurante.com
					<br />
					Objeto Social: Restauración
					<br />
					Sitio web: https://beginrestaurante.com/
					<br />
					Datos registrales: Inscrita en el Registro Mercantil de Valencia, Tomo
					10754, Libro 8034, Folio 132, Sección 8, Hoja 190498, Inscripción 11.
					<br />
				</p>
				<h2>3. Definiciones</h2>
				<p>
					Para dar cumplimiento al apartado segundo del artículo 22 de la
					LSSICE, la información ofrecida en esta política debe ser clara y
					completa sobre la utilización de los dispositivos de almacenamiento y
					recuperación de datos y, en particular, sobre los fines del
					tratamiento de los datos que requiere que el tratamiento de los datos
					de los usuarios se realice de forma transparente para ellos. Para
					conseguirlo, ofrecemos unas definiciones básicas y previas que se
					utilizarán en esta política, para mayor detalle y mejor comprensión
					por parte del usuario:
				</p>
				<p>
					<u>Usuario</u>: Es la persona física que accede a nuestra página web.
					<br />
					<u>Editor</u>: En el caso de esta página web, se trata de la entidad
					(persona física o jurídica) propietaria de la página web. También
					denominada responsable o prestador de servicios de la sociedad de la
					información.
					<br />
					<u>Cookies</u>: Las cookies y/o tecnologías similares (en adelante,
					cookies) almacenan y recuperan información cuando navega. En general,
					estas tecnologías pueden servir para finalidades muy diversas, como,
					por ejemplo, reconocer a los usuarios, obtener información sobre sus
					hábitos de navegación, o personalizar la forma en que se muestra el
					contenido.
					<br />
					<u>Dato</u>: Es la información del usuario que se obtiene a través de
					las cookies.
					<br />
					El dato podrá llegar a ser de carácter personal cuando se trata de
					información sobre personas físicas identificadas o identificables, en
					los términos que establece el artículo 4 del RGPD.
					<br />
					<u>Servicio de la sociedad de la información</u>: Es todo servicio
					prestado a petición individual de un usuario, a título oneroso o no, a
					distancia y por vía electrónica, siempre que constituya una actividad
					económica para el editor cuya prestación origina la utilización de las
					cookies.
				</p>
				<h2>4. Tipos de cookies existentes</h2>
				<p>
					Mostramos a continuación, una clasificación de las cookies en función
					de una serie de categorías. No obstante, una misma cookie puede estar
					incluida en más de una categoría:
				</p>
				<h3>4.1. Tipos de cookies según la entidad que las gestione</h3>
				<p>
					Según quién sea la entidad que gestione el equipo o dominio desde
					donde se envían las cookies y trate los datos que se obtengan, podemos
					distinguir entre: <br />
					<u>Cookies Propias</u>
					: Son aquellas de las que es responsable nuestra entidad y que,
					generalmente, se envían al equipo terminal del usuario desde un equipo
					o dominio gestionado por nuestra entidad y desde el que se presta el
					servicio solicitado por el usuario.
					<br />
					<u>Cookies de Terceros</u>: Son aquellas de las que es responsable una
					entidad distinta a nuestra entidad y que, generalmente, se envían al
					equipo terminal del usuario desde un equipo o dominio que no es
					gestionado por nuestra entidad, sino por otra entidad que trata los
					datos obtenidos a través de las cookies.
				</p>
				<p>
					En el caso de que las cookies sean servidas desde un equipo o dominio
					gestionado por nuestra entidad, pero la información que se recoja
					mediante estas sea gestionada por un tercero, también serán
					consideradas como cookies de terceros (por ejemplo, la mejora de los
					servicios que presta o la prestación de servicios de carácter
					publicitario a favor de otras entidades).
				</p>
				<p>
					Debe saber que, si acepta las cookies de terceros y posteriormente
					desea revocar su consentimiento, deberá eliminarlas desde las opciones
					del navegador usado o desde el sistema ofrecido por el propio tercero.
					A continuación, le detallamos sitios que podrá visitar para conocer
					mejor las cookies de terceros (desactivación, posibles transferencias
					de datos a terceros países, etc.):
				</p>
				<p>
					<a href="https://support.google.com/chrome/answer/95647?hl=es">
						Google Chrome
					</a>
					<br />
					<a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">
						Mozilla Firefox
					</a>
					<br />
					<a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
						Microsoft Edge
					</a>
					<br />
					<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=es-419">
						Chrome para Android
					</a>
					<br />
					<a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
						Safari (Apple)
					</a>
					<br />
					<a href="https://www.habilitarlascookies.com/como-habilitar-las-cookies-en-opera.html">
						Opera
					</a>
					<br />
					Asi como{" "}
					<a href="https://policies.google.com/technologies/cookies?hl=es&gl=es">
						Google Adwords
					</a>
					<br />
				</p>
				<p>
					Si se utilizan otros navegadores, recomendamos consultar su política
					de uso y gestión de cookies.
				</p>
				<p>
					Le puede ser muy útil al usuario la herramienta{" "}
					<a href="https://www.youronlinechoices.com/es/">
						https://www.youronlinechoices.com/es/
					</a>{" "}
					para gestionar el uso de las cookies y para proteger su privacidad en
					internet.
				</p>
				<h3>4.2. Tipo de cookies según su finalidad</h3>
				<p>
					Existen muchas finalidades para el uso de las cookies. Según la
					finalidad para la que se traten los datos obtenidos a través de las
					cookies, algunas de las finalidades pueden ser: <br />
					<u>Cookies Técnicas</u>: <br />
					Las cookies técnicas se podrán utilizar sin consentimiento de los
					usuarios. <br />
					Son aquellas que permiten al usuario la navegación a través de nuestra
					página web y la utilización de las diferentes opciones o servicios que
					en ella existan, incluyendo aquellas que nuestra entidad utiliza para
					permitir la gestión y operativa de la página web y habilitar sus
					funciones y servicios, como, por ejemplo, controlar el tráfico y la
					comunicación de datos, identificar la sesión, acceder a partes de
					acceso restringido, recordar los elementos que integran un pedido,
					realizar el proceso de compra de un pedido, gestionar el pago,
					controlar el fraude vinculado a la seguridad del servicio, realizar la
					solicitud de inscripción o participación en un evento, contar visitas
					a efectos de la facturación de licencias del software con el que
					funciona el servicio (sitio web, plataforma o aplicación), utilizar
					elementos de seguridad durante la navegación, almacenar contenidos
					para la difusión de vídeos o sonido, habilitar contenidos dinámicos
					(por ejemplo, animación de carga de un texto o imagen) o compartir
					contenidos a través de redes sociales. <br />
					También pertenecen a esta categoría, aquellas cookies que permiten la
					gestión, de la forma más eficaz posible, de los espacios publicitarios
					que, como un elemento más de diseño o “maquetación” del servicio
					ofrecido al usuario, el editor haya incluido en una página web,
					aplicación o plataforma en base a criterios como el contenido editado,
					sin que se recopile información de los usuarios con fines distintos,
					como puede ser personalizar ese contenido publicitario u otros
					contenidos. <br />
					<u>Cookies de Preferencias o Personalización</u>: <br />
					Son aquellas que permiten recordar información para que el usuario
					acceda al servicio con determinadas características que pueden
					diferenciar su experiencia de la de otros usuarios, como, por ejemplo,
					el idioma, el número de resultados a mostrar cuando el usuario realiza
					una búsqueda, el aspecto o contenido del servicio en función del tipo
					de navegador a través del cual el usuario accede al servicio o de la
					región desde la que accede al servicio, etc. <br />
					Para que estas cookies se consideren exentas de información y
					consentimiento del usuario para su uso, este deberá limitarse al
					necesario para su propósito, y la información que pueda desprenderse
					de la selección del usuario no podrá emplearse para otros fines (por
					ejemplo, para la personalización de contenidos publicitarios) ni para
					elaborar un perfil del usuario. <br />
					<u>Cookies de Análisis o Medición</u>: <br />
					Son aquellas que, tratadas por nuestra entidad o por terceros, nos
					permiten cuantificar el número de usuarios y así realizar la medición
					y análisis estadístico de la utilización que hacen los usuarios del
					servicio ofertado. <br />
					Para ello se analiza su navegación en nuestra página web con el fin de
					mejorar la oferta de productos o servicios que le ofrecemos. <br />
					Es poco probable que el uso de estas cookies represente un riesgo para
					la privacidad de los usuarios. <br />
					<u>Cookies de Publicidad Comportamental</u>: <br />
					Son aquellas que, tratadas por nosotros o por terceros, nos permiten
					analizar los hábitos de navegación del usuario en Internet para que
					podamos mostrarle publicidad relacionada con su perfil de navegación.
					<br />
					<h3>4.3. Tipos de cookies según su duración</h3>
					<p>
						Según el plazo de tiempo que permanecen activadas en el equipo
						terminal podemos distinguir:
						<br />
						<u>De sesión</u>: <br />
						Son aquellas diseñadas para recabar y almacenar datos mientras el
						usuario accede a una página web. <br />
						Se suelen emplear para almacenar información que solo interesa
						conservar para la prestación del servicio solicitado por el usuario
						en una sola ocasión (por ejemplo, una lista de productos adquiridos)
						y desaparecen al terminar la sesión.
						<u>Persistentes</u>: <br />
						Son aquellas en las que los datos siguen almacenados en el terminal
						y pueden ser accedidos y tratados durante un periodo definido por el
						responsable de la cookie, y que puede ir de unos minutos a varios
						años. <br />
						Este tipo de cookies pueden suponer riesgos para la privacidad del
						usuario. En todo caso, cuando se instalen cookies persistentes,
						reduciremos al mínimo necesario su duración temporal atendiendo a la
						finalidad de su uso. <br />
					</p>
					<h3>4.4. Tipos de cookies exceptuadas de consentimiento</h3>
					<p>
						Las cookies siguientes quedan excluidas del ámbito de aplicación del
						artículo 22.2 de la LSSICE, y por tanto, no precisarán de
						información o consentimiento para su uso por parte del usuario:{" "}
						<br />
						• Cookies de “entrada del usuario” <br />
						• Cookies de autenticación o identificación de usuario (únicamente
						de sesión). <br />
						• Cookies de seguridad del usuario <br />
						• Cookies de sesión de reproductor multimedia. <br />
						• Cookies de sesión para equilibrar la carga. <br />
						• Cookies de personalización de la interfaz de usuario. <br />
						• Determinadas cookies de complemento (plug-in) para intercambiar
						contenidos sociales. <br />
						• Permitir únicamente la comunicación entre el equipo del usuario y
						la red. <br />
						• Estrictamente prestar un servicio expresamente solicitado por el
						usuario. <br />
					</p>
				</p>
				<h3>4.5. Tipos de cookies utilizadas en este sitio web</h3>
				<p>
					A continuación se incluye listado de cookies utilizadas en esta página
					web, organizadas según su finalidad, incluyendo si es propia o de
					terceros y su utilidad concreta:
				</p>
				<p>
					<table>
						<thead>
							<tr>
								<th colSpan={4}>Tecnicas</th>
							</tr>
							<tr>
								<th>Cookie</th>
								<th>Propietario</th>
								<th>Duracion</th>
								<th>Finalidad</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>gatsby-gdpr-facebook-pixel</td>
								<td>Propia</td>
								<td>3 meses</td>
								<td>
									Gestiona el consentimiento del usuario para el uso de unas
									cookies concretas
								</td>
							</tr>
							<tr>
								<td>gatsby-gdpr-google-analytics</td>
								<td>Propia</td>
								<td>1 año</td>
								<td>
									Gestiona el consentimiento del usuario para el uso de unas
									cookies concretas
								</td>
							</tr>
							<tr>
								<td>gatsby-gdpr-google-tagmanager</td>
								<td>Propia</td>
								<td>1 año</td>
								<td>
									Gestiona el consentimiento del usuario para el uso de unas
									cookies concretas
								</td>
							</tr>
							<tr>
								<td>gatsby-gdpr-hotjar</td>
								<td>Propia</td>
								<td>1 año</td>
								<td>
									Gestiona el consentimiento del usuario para el uso de unas
									cookies concretas
								</td>
							</tr>
							<tr>
								<td>gatsby-gdpr-linked-in</td>
								<td>Propia</td>
								<td>1 año</td>
								<td>
									Gestiona el consentimiento del usuario para el uso de unas
									cookies concretas
								</td>
							</tr>
							<tr>
								<td>gatsby-gdpr-tiktok-pixel</td>
								<td>Propia</td>
								<td>1 año</td>
								<td>
									Gestiona el consentimiento del usuario para el uso de unas
									cookies concretas
								</td>
							</tr>
							<tr>
								<td>cookiesAccepted</td>
								<td>Propia</td>
								<td>Sesión</td>
								<td>
									Gestiona el consentimiento del usuario para el uso de las
									cookies en la página web
								</td>
							</tr>
							<tr>
								<td>cookiesStored</td>
								<td>Propia</td>
								<td>Sesión</td>
								<td>Almacenamiento de las cookies en el dispositivo</td>
							</tr>
						</tbody>
					</table>

					<table>
						<thead>
							<tr>
								<th colSpan={4}>Analisis</th>
							</tr>
							<tr>
								<th>Cookie</th>
								<th>Propietario</th>
								<th>Duracion</th>
								<th>Finalidad</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>_ga</td>
								<td>Google</td>
								<td>13 meses</td>
								<td rowSpan={2}>
									Generar un identificador de usuario único, que es el que se
									utiliza para hacer recuento de cuántas veces visita el sitio
									un usuario, así como la fecha de la primera y la última vez
									que visitó la web. Registrar la fecha y hora de acceso a
									cualquiera de las páginas del Sitio. Comprobar la necesidad de
									mantener la sesión de un usuario abierta o crear una nueva.
									Identificar la sesión del usuario, para recoger la ubicación
									geográfica aproximada del ordenador que accede al Sitio con
									efectos estadísticos.
								</td>
							</tr>
							<tr>
								<td>_ga_XTEGFBD82P</td>
								<td>Google</td>
								<td>13 meses</td>
							</tr>
							<tr>
								<td>ar_debug</td>
								<td>Propia</td>
								<td>3 meses</td>
								<td>Almacena y hace seguimiento de conversiones</td>
							</tr>
						</tbody>
					</table>
				</p>

				<p>
					En cualquier momento, el usuario podrá retirar el consentimiento
					otorgado inicialmente activando la ventana informativa de cookies
					desde donde otorgó su consentimiento. Se podrá acceder a esta ventana
					en la página de inicio de nuestra página web.
				</p>

				<p>
					Recomendamos revisar nuestra política de cookies cada vez que acceda a
					nuestro sitio web para informarse de cualquier modificación sobre el
					uso de nuestras cookies. Nuestra entidad se reserva el derecho a la
					modificación de esta Política sin previo aviso.
				</p>
				<h2>5. Elaboración de perfiles</h2>

				<p>
					Como parte del deber de información del artículo 13.2 f) del RGPD, le
					comunicamos que, en caso de realizar perfiles sobre su persona, le
					solicitaremos consentimiento previo. No obstante, debe saber que puede
					oponerse a ser objeto de una decisión automatizada de sus datos, donde
					se evalúen aspectos personales, como puede ser analizar o predecir
					aspectos relacionados con su rendimiento en el trabajo, situación
					económica, salud, las preferencias o intereses personales, fiabilidad
					o el comportamiento, de manera que a consecuencia de ello se produzcan
					efectos jurídicos sobre su persona o le afecte de una manera similar.
					<br />
					Nuestra entidad adoptará las medidas de seguridad adecuadas que
					protejan sus derechos y libertades, así como sus intereses legítimos,
					como son el derecho a obtener intervención humana por nuestra parte
					para expresar tu punto de vista e impugnar la decisión.
				</p>

				<h2>6. Transferencia de datos</h2>
				<p>
					Sus datos personales no se cederán a otros países ni a terceros. En
					caso de cesiones a otras entidades o a otros países fuera de la Unión
					Europea, le informaremos y solicitaremos su consentimiento previo y
					expreso.
				</p>

				<h2>7. Legitimación para el uso de cookies</h2>
				<p>
					Si el usuario pulsa el botón “ACEPTAR cookies”, incluido en la ventana
					informativa de cookies mostrada al acceder a nuestra web, se entiende
					que libremente presta su consentimiento expreso para el uso de todas
					las cookies, lo que permitirá a nuestra entidad usar la información
					del usuario.
					<br />
					Si el usuario pulsa libremente el botón destinado a personalizar o
					configurar el uso de cookies, ofreciéndole un espacio para seleccionar
					las cookies que desee que sean usadas.
					<br />
					En cualquier momento, el usuario puede cambiar de opinión y revocar el
					consentimiento otorgado inicialmente para el uso de cookies.
				</p>

				<h2>8. Rechazar el uso de cookies</h2>
				<p>
					Si el usuario pulsa libremente el botón “RECHAZAR cookies” incluido en
					la ventana informativa de cookies mostrada al acceder a nuestra web,
					decide que no se usen cookies.
					<br />
					El usuario dispone en la ventana informativa de cookies, de un botón
					destinado a personalizar o configurar el uso de cookies, ofreciéndole
					un espacio para deseleccionar las cookies que previamente haya
					consentido que sean usadas.
				</p>

				<h2>9. Plazo de conservación</h2>
				<p>
					El plazo de conservación de los datos personales, será el menor
					posible de acuerdo con la finalidad para la cual se recabaron estos
					datos, atendiendo al principio de minimización de los datos. Para más
					información sobre el tratamiento de datos personales consulte nuestra
					<Link to="/legal/politica-privacidad">Política de Privacidad</Link>.
				</p>

				<h2>10. Sus Derechos</h2>
				<p>
					Usted tiene derecho a conocer si nuestra entidad está tratando sus
					datos personales; por tanto, tiene derecho a acceder a estos,
					rectificarlos si son inexactos o solicitar su supresión cuando los
					datos ya no sean necesarios, siempre que sea legalmente posible.
					<br />
					También puede ejercer su derecho de oposición, limitación o
					portabilidad si así lo estima conveniente, incluso podrá revocar su
					consentimiento y para ejercer cualquiera de estos derechos, deberá
					hacerlo por escrito a{" "}
					<a href="contacto@beginrestaurante.com">
						contacto@beginrestaurante.com
					</a>{" "}
					acompañando una copia de su DNI o documento equivalente para para
					facilitarnos su identificación.
					<br />
					Si quien desea ejercer sus derechos es menor de 14 años, podrá hacerlo
					a través de sus padres, tutores o representante legal.
				</p>

				<p>
					Si desea trasladar alguna sugerencia o consulta sobre el tratamiento
					de sus datos personales, puede contactar con nuestros consultores de
					protección de datos, indicando el nombre de nuestra empresa o el
					nombre comercial: <br />
					BUSINESS ADAPTER, S.L. <br />
					Ronda Guglielmo Marconi, 11, 26, (Parque Tecnológico) 46980 Paterna
					(Valencia). <br />
					<a href="https://www.businessadapter.es/contacta-con-el-delegado-de-proteccion-de-datos">
						Formulario de atención al interesado
					</a>
				</p>

				<p>
					Le informamos que si usted en tiende que sus derechos han sido
					desatendidos, tiene derecho a realizar una reclamación ante la Agencia
					Española de Protección de Datos en: C/ Jorge Juan, 6, 28001 Madrid o
					en <a href="www.aepd.es">www.aepd.es</a>.
				</p>

				<h2>11. Actualización de esta Política</h2>
				<p>
					Nuestra entidad se reserva el derecho a la modificación de esta
					Política sin previo aviso. Es por ello, que recomendamos consultarla
					cada vez que visite nuestra web.
				</p>

				<p style={{ textAlign: "right" }}>
					Actualizado el 6 de octubre de 2023.
				</p>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
